import React from "react"
import { Divider, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import TodayIcon from '@mui/icons-material/Money';
import PeopleIcon from '@mui/icons-material/People';
import { Link, useLocation } from 'react-router-dom';

const MenuItem = ({
    title, link, icon, selected,
  }) => (
    <ListItemButton key={link} component={Link} to={link} selected={selected}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={title} />
    </ListItemButton>
  );
  
const CustomMenu = () => {
    return <>
      <Divider />
      <List>
        <MenuItem
          title={"Recettes"}
          link="/custom/recettes"
          icon={<PeopleIcon />}
          selected={location.pathname.startsWith('/custom/recettes') && location.pathname !== `/custom/recettes/`}
        />
      </List>
    </>
}

export default CustomMenu