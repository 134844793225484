export const AUTH_LINKS = {
    login: "/login_check",
    tokenRefresh: "/token/refresh",
    me: "/me.json"
}

export const DATA_LINKS = {
    prestationOrders: "/prestation_orders",
    prestations: "/prestations",
    prestataires: "/prestataires",
    parcelle_cycles: "/parcelle_cycles",
    parcelles: "/parcelles",
    cultures: "/cultures",
    producers: "/producers",
    cycles: "/cycles",
    cooperatives: "/cooperatives",
    villages: "/villages",
    engins: "/devices",
    sequences: "/prestation_sequences",
    prestationCategories: "/prestation_categories",
    prestationResponses: "/prestation_responses"
};

export const LOGO_URL = "/imgs/logo/logo.png";